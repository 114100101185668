import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const faultCodesApi = createApi({
	reducerPath: "faultCodes",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		sendEmailFaultCodes: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes/email",
				method: "POST",
				body: requestPayload,
			}),
		}),
		getFaultCodes: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getFaultCodesChart: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "/faultCodes/topFaults",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
	}),
});

export const { useSendEmailFaultCodesMutation, useGetFaultCodesMutation, useGetFaultCodesChartMutation } = faultCodesApi;
