import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { downtimeApi } from "../../services/downtimeApi";
import { DowntimePageState, DowntimeTable } from "./downtimePageTypes";

const initialState: DowntimePageState = {
	reloadPage: false,
	dialogRecurrenceState: false,
	tableDowntimeData: {
		isLoading: true,
		data: {
			data: [],
			totalData: 0,
			info: [],
			totalAvailability: 0,
			summary: undefined,
		},
	},
};

const downtimePageSlice = createSlice({
	name: "downtimePage",
	initialState,
	reducers: {
		triggerReloadPage: (state) => {
			state.reloadPage = !state.reloadPage;
		},
		setTableDowntimeData: (state, action: PayloadAction<DowntimeTable>) => {
			state.tableDowntimeData.data = action.payload;
		},
		setTableDowntimeDataLoading: (state, action: PayloadAction<boolean>) => {
			state.tableDowntimeData.isLoading = action.payload;
		},
		openDialogRecurrence: (state) => {
			state.dialogRecurrenceState = true;
		},
		closeDialogRecurrence: (state) => {
			state.dialogRecurrenceState = false;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(downtimeApi.endpoints.getDowntimeRecords.matchPending, (state) => {
			state.tableDowntimeData.isLoading = true;
		});
		builder.addMatcher(downtimeApi.endpoints.getDowntimeRecords.matchFulfilled, (state, { payload }) => {
			state.tableDowntimeData.isLoading = false;
			state.tableDowntimeData.data = payload;
		});
	},
});

export const { triggerReloadPage, openDialogRecurrence, closeDialogRecurrence } = downtimePageSlice.actions;
export const getDialogState = (state: RootState) => state.pageDowntime.dialogRecurrenceState;
export const getDowntimePageData = (state: RootState) => state.pageDowntime;
export default downtimePageSlice.reducer;
