import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { DashboardPageState } from "./dashboardPageTypes";

const initialState: DashboardPageState = {
	paramsFilter: "none",
	openDialogDigitalSchedule: {
		open: false,
		chassis: "",
		vehicle: "",
		vehicleId: "",
	},
};

const dashboardPageSlice = createSlice({
	name: "dashboardPage",
	initialState,
	reducers: {
		setParamsFilter: (state, action: PayloadAction<string>) => {
			state.paramsFilter = action.payload;
		},
		setOpenDialogDigitalSchedule: (state, action: PayloadAction<any>) => {
			state.openDialogDigitalSchedule = action.payload;
		},
	},
});

export const { setParamsFilter, setOpenDialogDigitalSchedule } = dashboardPageSlice.actions;
export const getFilterType = (state: RootState) => state.pageDashboard.paramsFilter;
export const getDashboardPageData = (state: RootState) => state.pageDashboard;
export default dashboardPageSlice.reducer;
