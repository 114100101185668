import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../configuration/setup/hooks";
import useQueryParams from "../hooks/useQueryParams";
import { addAssetsSelected, addrangeIdAssets, addrangeIdTagss, addTagsSelected, getAppData, setAppDate } from "../stores/app/appSlice";
import { getRangesFromIds, rangesToString, stringToRanges } from "../utils/url-state";
import { parseRoute } from "./routeHelper";
import { useRouteState } from "./routeHooks";
import { paramsTruckIds, paramsTruckTags } from "../constants/params";

const RouteUpdater = () => {
	const {
		assets: assetsGlobal,
		tags: tagsGlobal,
		allAssetsWithoutTag,
		allTags,
		allAssets,
		dateTime,
		rangeIdAssets,
		rangeIdTags,
	} = useAppSelector(getAppData);
	const { setQueryParam, deleteQueryParam, getQueryParam } = useQueryParams();
	const dispatch = useAppDispatch();
	const { search } = useLocation();

	// convert UUID from global state to range
	useEffect(() => {
		const findIdsFromTag: number[] = [];
		const findIdsFromIds: number[] = [];

		allTags.forEach((tag, index) => {
			if (tagsGlobal.some((globalTag) => globalTag === tag.id)) {
				findIdsFromTag.push(index);
			}
		});

		allAssets?.forEach((truck, index) => {
			if (assetsGlobal.some((globalTag) => globalTag === truck.id)) {
				findIdsFromIds.push(index);
			}
		});

		dispatch(addrangeIdAssets(findIdsFromIds));
		dispatch(addrangeIdTagss(findIdsFromTag));

		if (allTags.length > 0 && tagsGlobal.length == 0) deleteQueryParam(paramsTruckTags);
		if (allAssets.length > 0 && assetsGlobal.length == 0) deleteQueryParam(paramsTruckIds);
	}, [tagsGlobal, assetsGlobal]);

	// global state management
	useEffect(() => {
		const routeSearchParams = parseRoute(search);
		const { truckTags, truckIds } = routeSearchParams;
		if (!allAssets) return;

		if (truckTags) {
			const decodedTruckTags = stringToRanges(getQueryParam(paramsTruckTags)!);
			const validTruckTags = allTags || [];
			const trucksTagUUID = decodedTruckTags.map((index: number) => validTruckTags[index]?.id).filter((id) => id !== undefined);
			dispatch(addTagsSelected(trucksTagUUID));
			if (trucksTagUUID.length == allTags.length) {
				dispatch(addAssetsSelected(allAssets.map((item) => item.id)));
			}
		}

		if (truckIds) {
			const validTruckIds = allAssets || [];
			const decodedTruckIds = stringToRanges(getQueryParam(paramsTruckIds)!);
			const trucksIdsUUID = decodedTruckIds.map((index: number) => validTruckIds[index]?.id).filter((id: string) => id !== undefined);
			dispatch(addAssetsSelected(trucksIdsUUID));
		}
	}, [allAssets]);

	// url management
	useEffect(() => {
		if (
			assetsGlobal.length > 0 &&
			rangeIdAssets.length > 0 &&
			tagsGlobal.length !== allTags.length &&
			assetsGlobal.length !== allAssets.length &&
			assetsGlobal !== allAssetsWithoutTag
		) {
			setQueryParam(paramsTruckIds, rangesToString(getRangesFromIds(rangeIdAssets)));
		}

		if (tagsGlobal.length == 0 && assetsGlobal.length > 0 && rangeIdAssets.length > 0) {
			setQueryParam(paramsTruckIds, rangesToString(getRangesFromIds(rangeIdAssets)));
		}

		if (tagsGlobal.length > 0 && rangeIdTags.length > 0) {
			setQueryParam(paramsTruckTags, rangesToString(getRangesFromIds(rangeIdTags)));
		}
	}, [rangeIdTags, rangeIdAssets]);

	useRouteState(() => {
		const routeSearchParams = parseRoute(search);
		const { date } = routeSearchParams;

		if (date) {
			const dateUrl = JSON.parse(date);

			if (dateUrl.start !== dateTime.start || dateUrl.end !== dateTime.end) {
				dispatch(setAppDate(dateUrl));
			}
		}
	});

	return null;
};

export default RouteUpdater;
