import { createSlice } from "@reduxjs/toolkit";
import { endOfDay, format, startOfDay } from "date-fns";
import type { RootState } from "../../configuration/setup/store";
import { AppState } from "./appSlideTypes";

const initialState: AppState = {
	assets: [],
	tags: [],
	rangeIdAssets: [],
	rangeIdTags: [],
	allAssets: [],
	allAssetsWithoutTag: [],
	allTags: [],
	sessionExpiredAcknowledged: false,
	sidebar: true,
	screenType: {
		MOBILE_SCREEN: false,
		TABLET_SCREEN: false,
		DESKTOP_SCREEN: false,
	},
	dateTime: {
		start: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
		end: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
	},
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setTrucksTagsGlobal: (state, { payload }) => {
			state.allTags = payload;
		},
		setTrucksGlobal: (state, { payload }) => {
			state.allAssets = payload;
		},
		setAllTrucksWithoutTag: (state, { payload }) => {
			state.allAssetsWithoutTag = payload;
		},
		hideSessionExpiredDialog: (state) => {
			state.sessionExpiredAcknowledged = true;
		},
		addTagsSelected: (state, { payload }) => {
			state.tags = payload;
		},
		setScreenType: (state, action) => {
			state.screenType = action.payload;
		},
		addAssetsSelected: (state, { payload }) => {
			state.assets = payload;
		},
		addrangeIdAssets: (state, { payload }) => {
			state.rangeIdAssets = payload;
		},
		addrangeIdTagss: (state, { payload }) => {
			state.rangeIdTags = payload;
		},
		setAppDate: (state, { payload }) => {
			state.dateTime = payload;
		},
	},
});

export const {
	hideSessionExpiredDialog,
	setTrucksTagsGlobal,
	setTrucksGlobal,
	setAllTrucksWithoutTag,
	addrangeIdAssets,
	addrangeIdTagss,
	setScreenType,
	addTagsSelected,
	addAssetsSelected,
	setAppDate,
} = appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;
export const getAppData = (state: RootState) => state.app;
export const getAppDate = (state: RootState) => state.app.dateTime;
export const getSelectedallTags = (state: RootState) => state.app.tags;
export const getSelectedTruckIds = (state: RootState) => state.app.assets;

export default appSlice.reducer;
